import React, { createElement, useEffect, useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion';
import loadable from '@loadable/component'
import { useSearchParam } from 'react-use';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/modal';

import Layout from '../Layout';
import Box from '../../components/Box';
import Text from '../../components/Text';
import Button from '../../components/Button';
import VocanoLoading from './VocanoLoading';

const Pages = loadable(() => import('./Pages'))

const IndexPage = ({ data: { allFile: { nodes: images }, lottie: { nodes: lottie } } }) => {
  const [initMounted, setInitMounted] = useState()
  const [initLoaded, setInitLoaded] = useState()
  const [pageLoaded, setPageLoaded] = useState(0)
  const vocanoLottie = useMemo(() => lottie.find(l => l.name === 'about'), [lottie])
  useEffect(() => {
    setInitMounted(true)
  }, [])
  const done = useSearchParam('validate')
  const [isOpen, setOpne] = useState(done)
  return (
    <Layout pt="0">
      {initMounted && createElement(Pages, {
        pageLoaded,
        setPageLoaded,
        images,
        lottie,
      })}
      <AnimatePresence>
        {(!initLoaded || pageLoaded <= 1) && (
          <motion.div
            transition={{ duration: 1.5 }}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'fixed',
              background: 'white',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
            }}
          >
            <VocanoLoading onLoad={() => setPageLoaded(1)} onComplete={() => setInitLoaded(1)} src={vocanoLottie?.publicURL} />
          </motion.div>
        )}
      </AnimatePresence>
      <Modal isCentered isOpen={isOpen} onClose={() => setOpne(false)}>
        <ModalOverlay />
        <ModalContent width="73.33%" px="1.75em" pt="2.5em" pb="3.125em">
          <Text fontSize="1.125em">恭喜你完成關注！</Text>
          <Text mt="1.625em" lineHeight="2.285" fontSize="0.875em">接下來，台灣發生什麼數的電子報將寄到你的信箱，讓你搶先知道最新好玩的數！</Text>
          <Box textAlign="center" mt="3.125em">
            <Button.Black py="0.375rem" px="2.125rem" fontSize="1.125rem" lineHeight="1.77" width="auto" onClick={() => setOpne(false)}>繼續逛逛</Button.Black>
          </Box>
        </ModalContent>
      </Modal>
    </Layout>
  )
}

export default IndexPage
