import { graphql } from 'gatsby'
import Page from '../containers/HomePage'

export default Page

export const query = graphql`
query HomeQuery {
  allFile(filter: {sourceInstanceName: {eq: "homepage_images"}}) {
    nodes {
      name
      publicURL
      relativePath
    }
  }
  lottie: allFile(filter: {sourceInstanceName: {eq: "homepage_lottie"}}) {
    nodes {
      name
      publicURL
      relativePath
    }
  }
}
`
